<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="list_page">
            <div class="box_con">
              <div class="box_list_wrap">
                <div class="box_list border flex_box" @click="fnPage(0)">
                  <div class="box_list_ttl">
                    <img src="@/assets/static/images/icon_01.svg" alt="icon" />
                    <p>빌라 정보 등록</p>
                  </div>
                  <div class="icon"></div>
                </div>
                <div
                  class="box_list border flex_box"
                  @click="fnPage(1)"
                  v-show="status === 2"
                >
                  <div class="box_list_ttl">
                    <img src="@/assets/static/images/icon_07.svg" alt="icon" />
                    <p>민원 접수</p>
                  </div>
                  <div class="icon"></div>
                </div>
                <div
                  class="box_list border flex_box"
                  @click="fnPage(2)"
                  v-show="status === 2"
                >
                  <div class="box_list_ttl">
                    <img src="@/assets/static/images/icon_05.svg" alt="icon" />
                    <p>찬반 투표</p>
                  </div>
                  <div class="icon"></div>
                </div>
                <div
                  class="box_list border flex_box"
                  @click="fnPage(3)"
                  v-show="status === 2"
                >
                  <div class="box_list_ttl">
                    <img src="@/assets/static/images/icon_04.svg" alt="icon" />
                    <p>공지사항</p>
                  </div>
                  <div class="icon"></div>
                </div>
                <div
                  class="box_list border flex_box"
                  @click="fnPage(4)"
                  v-show="status === 2"
                >
                  <div class="box_list_ttl">
                    <img src="@/assets/static/images/icon_06.svg" alt="icon" />
                    <p>입주민 소통 공간</p>
                  </div>
                  <div class="icon"></div>
                </div>
                <div
                  class="box_list border flex_box"
                  @click="fnPage(5)"
                  v-show="status === 2"
                >
                  <div class="box_list_ttl">
                    <img src="@/assets/static/images/icon_08.svg" alt="icon" />
                    <p>빌라 현황</p>
                  </div>
                  <div class="icon"></div>
                </div>
                <div class="box_list border flex_box" @click="fnPage(6)">
                  <div class="box_list_ttl">
                    <img src="@/assets/static/images/icon_03.svg" alt="icon" />
                    <p>자주 묻는 질문</p>
                  </div>
                  <div class="icon"></div>
                </div>
                <div class="box_list border flex_box" @click="fnPage(7)">
                  <div class="box_list_ttl">
                    <img src="@/assets/static/images/icon_02.svg" alt="icon" />
                    <p>설정</p>
                  </div>
                  <div class="icon"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { reactive, toRefs, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'menu-index',
  description: '메뉴 리스트',
  setup() {
    const { getters } = useStore()
    const router = useRouter()
    const userData = computed(() => getters['user/getData'])

    const state = reactive({
      status: userData.value.status
    })

    const fnPage = (index = 0) => {
      /*
        0: 빌라 정보 등록
        1: 민원 접수
        2: 찬반 투표
        3: 공지사항
        4: 입주민 소통공간
        5: 빌라 현황
        6: 자주 묻는 질문
        7: 설정
         */
      const paths = [
        '/menu/building/register',
        '/menu/complaint',
        '/menu/vote',
        '/menu/board/notice',
        '/menu/notice/tenant',
        '/menu/building/status',
        '/menu/board/faq',
        '/menu/setting'
      ]
      router.push({ path: paths[index] })
    }

    return { ...toRefs(state), fnPage }
  }
}
</script>

<style scoped></style>
